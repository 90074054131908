.logo_cert {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    text-decoration: none;
}

.logo_elements {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}


.audit_by {
    font-size: 40px;
    color: #FBBF04;
    padding-bottom: 9px;
}

@media only screen and (max-width:1200px) {
    .logo_cert {
        margin-top: 0px;
    }
}

@media only screen and (max-width:1000px) {
    .certImg1 {
        width: 90px;
    }

    .certImg2 {
        width: 20px;
    }

    .audit_by {
        font-size: 12px;
        padding-bottom: 6px;
    }

    .logo_cert {
        margin-top: 40px;
    }
}