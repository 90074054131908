.recharge-card{
    width:100%;
    margin-top: -130px;
    max-width: 1066px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.animated-div{
    background: transparent;
    border: none;
    cursor: pointer;
}