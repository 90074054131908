.repsponsive-rotate {
  width: 100%;
}

.main-relative-cont {
  position: relative;
}

.recharge-card {
  animation: floating 4s infinite;
  -webkit-animation: floating 4s infinite;
}

@keyframes floating {


  0% {
    -webkit-transform: rotateX(0deg) translateY(0);
    transform: rotateX(0deg) translateY(0);
  }

  50% {
    -webkit-transform: rotateX(0deg) translateY(15px);
    transform: rotateX(0deg) translateY(15px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translateY(0);
    transform: rotateX(0deg) translateY(0);
  }

}

#motion-demo {
  offset-path: path("M828 695.097L423.123 5.53709C419.259 -1.04523 409.741 -1.04523 405.877 5.53709L1 695.097");
  stroke: "#FBBF04";
  animation: move 3000ms infinite alternate ease-in-out;
}

@keyframes move {
  0% {
    offset-distance: 0%;
  }

  100% {
    offset-distance: 100%;
  }
}

.tokenomics-image {
  cursor: pointer;
  opacity: 0.7;
}

.tokenomics-image:hover {
  opacity: 1;
}