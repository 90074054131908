.MediaContentQuery{
    display: block;
    position: relative;
}
.MediaContent{
    position: fixed;
    bottom: 0;
    background-color: black;
    border-top-width: 1px;
    border-top-color: #252222;
    border-style: solid;
    z-index: 999999;  
}
.MediaMobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media only screen and (min-width:568px){
    .MediaContentQuery{
        display: block;
    }
    .MediaMobile{
        display: none;
    }
}
@media only screen and (min-width:1200px){
    .MediaContent{
        padding-top: 0px;
    }
}

.fixedbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.seeIntheMedia{
    font-size: 22px;
}
@media only screen and (max-width:600px) {
    .seeIntheMedia{
        font-size: 18px;
    }    
}

.marquee_element{
    margin-left: 150px;
    margin-left: 150px;
}